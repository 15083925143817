/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import NextLink from 'next/link';

import { builder, Builder, BuilderComponent } from '@builder.io/react/lite';
import BuilderPageMetadata from '@glowforge/builder-components/lib/builder-page-metadata';
import registerComponents from '@glowforge/builder-components/lib/register';

import builderComponents from './builder/builder-components';
import { fetchProducts } from '../api/storefront';
import HeadTitle from '../components/head/head-title';
import { REACT_ROOT } from '../constants';
import ModalPriorityContext from '../context/ModalPriorityContext/ModalPriorityContext';
import ShareProgramContext, { parseReferralDiscount } from '../context/ShareProgramContext';
import LocationContext from '../context/LocationContext/LocationContext';
import UserContext from '../context/UserContext/UserContext';
import Canonical from './canonical';
import { getCookie, removeCookie, setCookie } from './cookies';
import builderTrackExperience from './tracking';
import { MagicSvgContext } from '../context/MagicSvgContext/MagicSvgContext';
import {
  MOCK_RECENT_IMAGES_DATA,
  MOCK_TOKENS,
  MOCK_IMAGE,
} from '../components/magic-svg/constants';
import { fetchArtStyles } from '../api/magic-svg';

/**
 * Returns the Builder Model objects so we can create page
 * representations consistently
 * @param {Object} props
 * @param {string} props.model
 * @param {string} props.pathPrefix This should not have a leading or trailing slash. ie "products/pro" not "/products/pro/"
 * @param {boolean} props.noIndex
 * @param {array} props.components
 * @returns
 */
// eslint-disable-next-line default-param-last
function builderPathInit({ model, pathPrefix, noIndex = false, components }) {
  builder.init('69fac0673d2644f0a0a962e1cb3045ef');
  Builder.register('editor.settings', {
    customInsertMenu: true,
  });

  const pageComponents = components && components.length > 0 ? components : [];

  registerComponents(Builder, [
    ...builderComponents,
    ...pageComponents
  ]);

  const ALLOWED_CACHE_HEADERS = {
    sMaxage: 's-maxage',
    staleWhileRevalidate: 'stale-while-revalidate',
    noCache: 'no-cache',
  };

  const {
    publicRuntimeConfig: {
      GF_DOTCOM_ENV,
      GF_DOTCOM_SHOPIFY_URL,
    },
  } = getConfig();

  /**
   * If prod env, only render explicitly production pages.
   * Staging and development envs can view all pages.
   */
  const builderComponentQuery =
    GF_DOTCOM_ENV === 'production'
      ? {
        query: {
          data: {
            environment: GF_DOTCOM_ENV ?? 'production',
          },
        },
      }
      : {};

  /**
   * @param {object} props
   * @param {string} [props.href]
   * @param {string} [props.target]
   * @returns
   */
  function renderLink(props) {
    // nextjs link doesn't handle hash links well if it's on the same page
    // (starts with #)
    if (props.target === '_blank' || props.href?.startsWith('#')) {
      return <a {...props}>{props.children}</a>;
    }
    const { children, href, ...rest } = props;
    return (
      <NextLink href={href} legacyBehavior>
        <a {...rest}>{children}</a>
      </NextLink>
    );
  }

  /**
   * Tracks the A/B variations in builder
   * @param {*} content
   */
  function trackVariation(content) {
    builderTrackExperience(content);
  }

  /**
   * BuilderPage
   * If page not found, then it renders 404 page. 404.jsx contains
   * code to render preview of builder content that has yet to be published.
   */
  function BuilderPage({
    content,
    title,
    includeNav,
    includeFooter,
    products,
    fetchReferralData,
    fetchUserData,
    additionalData,
    fetchMagicSvgData,
    isBlogSearchFound,
    isLatestArticles,
    artStyles,
  }) {
    // store data in local state, so we can update and pass to BuilderComponent
    // when it updates
    const [data, setData] = useState({
      ...additionalData,
      includeNav,
      includeFooter,
      products,
      artStyles
    });

    const [loadedContent, setLoadedContent] = useState({});
    const [currentReferral, setCurrentReferral] = useState({});
    const [currentAffiliate, setCurrentAffiliate] = useState({});
    const [currentUser, setCurrentUser] = useState({});
    const [isReturningVisitor, setIsReturningVisitor] = useState(false);
    const [magicSvgData, setMagicSvgData] = useState({});

    const { country: countryCode, hasFetched: hasFetchedLocation } =
      useContext(LocationContext);

    const { referralInfo, affiliateInfo } =
      useContext(ShareProgramContext);

    const { user, userFetchState } = useContext(UserContext);

    const {
      userToken,
      recentImages,
      page,
      image,
      generationStatus,
      showBanner,
      userAction,
    } = useContext(MagicSvgContext);

    useEffect(() => {
      // Ensure that we're adding permissions for Osano
      //  in order to be GDPR compliant
      builder.canTrack =
        window.Osano &&
        window.Osano.cm &&
        window.Osano.cm.getConsent &&
        window.Osano.cm.getConsent().ANALYTICS === 'ACCEPT';
    }, []);

    useEffect(() => {
      if (Object.keys(loadedContent).length) {
        trackVariation(loadedContent?.content);
      }

      if (loadedContent?.data?.navbarButtonUrl) {
        const buyButtons = document.querySelectorAll('.navbarButton a[href]');
        buyButtons.forEach((buyButton) => {
          if (buyButton) {
            buyButton.setAttribute('href', loadedContent.data.navbarButtonUrl);
          }
        });
      }
    }, [loadedContent]);

    useEffect(() => {
      // If you're logged in, no referral discounts for you
      if (currentUser?.email?.length > 0 && currentUser?.uuid?.length > 0) {
        setCurrentReferral(null);
        setCurrentAffiliate(null);
        return;
      }

      if (fetchReferralData && hasFetchedLocation && countryCode !== 'GB') {

        const hasReferral = referralInfo?.name?.length > 0 && referralInfo?.code?.length > 0;
        setCurrentReferral({
          name: referralInfo?.name || '',
          referralCode: referralInfo?.code || '',
          shopUrl: referralInfo?.shopUrl || `${GF_DOTCOM_SHOPIFY_URL}/collections/printers`,
          ...parseReferralDiscount(products),
        });

        setCurrentAffiliate({
          name: !hasReferral ? affiliateInfo?.name || '' : '',
          id: !hasReferral ? affiliateInfo?.code || '' : '',
        });
      }
    }, [
      affiliateInfo,
      countryCode,
      currentUser,
      fetchReferralData,
      hasFetchedLocation,
      products,
      referralInfo,
      setData,
    ]);

    useEffect(() => {
      if (fetchUserData && userFetchState === 'complete') {
        const cookieName = 'regular_visitor';
        const visitorCookie = getCookie(cookieName);
        // Know we found a user, and don't need a visitor cookie
        // Users have their own cookies set by logging in
        if (Object.keys(user).length > 0) {
          setCurrentUser(user);
          if (visitorCookie) removeCookie(cookieName);
        } else {
          setCookie(cookieName, 'true', 183, true);
          setIsReturningVisitor(true);
        }
      }
    }, [fetchUserData, userFetchState, user]);

    useEffect(() => {
      if (fetchMagicSvgData) {
        if (Builder.isEditing) {
          // set some mock state since builder doesn't have auth tokens to make these requests
          setMagicSvgData({
            userToken: MOCK_TOKENS,
            recentImages: MOCK_RECENT_IMAGES_DATA,
            page,
            image: MOCK_IMAGE,
            generationStatus,
            showBanner,
            userAction,
          });
        } else {
          setMagicSvgData({
            userToken: userToken.tokens?.toString() ?? '',
            recentImages,
            page,
            image,
            generationStatus,
            showBanner,
            userAction,
          });
        }
      }
    }, [
      fetchMagicSvgData,
      userToken.tokens,
      recentImages,
      page,
      image,
      generationStatus,
      showBanner,
      userAction,
    ]);

    if (!content) return null;

    return (
      <>
        {content?.data ? <BuilderPageMetadata {...content?.data} /> : null}
        {!!content?.data && title ? <HeadTitle title={title} /> : null}
        {content?.data?.noindex || noIndex ? (
          <Head>
            <meta name='robots' content='noindex' />
          </Head>
        ) : null}
        {content?.data?.url ? <Canonical path={content.data.url} /> : null}
        <BuilderComponent
          content={content}
          contentLoaded={(builderData, builderContent) => {
            setLoadedContent({ data: builderData, content: builderContent });
          }}
          model={model}
          renderLink={renderLink}
          options={{
            includeRefs: true,
            noTargeting: true,
          }}
          context={{
            // Used to coordinate builder.io modals with built-in modals
            ModalPriorityContext,
            REACT_ROOT,
          }}
          data={{
            affiliates: currentAffiliate,
            isReturningVisitor,
            referrals: currentReferral,
            user: currentUser,
            magicSvgData,
            isBlogSearchFound,
            isLatestArticles,
            ...data,
          }}
        />
      </>
    );
  }

  async function getServerSideProps(context) {
    const { params, res, req, query } = context;
    // Account for empty path prefix and home page
    const prefix = pathPrefix === '' ? '/' : `/${pathPrefix}`;
    // Account for no slug after a path prefix
    const urlPath = params?.slug
      ? `${prefix}/${params.slug.join('/')}`
      : prefix;

    if (pathPrefix[0] === '/' || pathPrefix[pathPrefix.length - 1] === '/') {
      console.error(
        'builderPathInit pathPrefix arg should not have a leading or trailing slash.'
      );
    }

    const content = await builder
      .get(model, {
        userAttributes: {
          urlPath,
          host: req.headers.host,
          ...builderComponentQuery,
        },
        ...query.builder,
      })
      .promise();

    if (!content) {
      return {
        notFound: true,
      };
    }

    const {
      title = null,
      cacheControl,
      includeNav = true,
      includeFooter = true,
      hideBuyButton = false,
      fetchProductData = false,
      fetchReferralData = false,
      fetchUserData = false,
      fetchMagicSvgData = false,
    } = content.data;

    let products = null;

    if (fetchProductData) {
      products = await fetchProducts();
    }

    let artStyles = null;

    if (fetchMagicSvgData) {
      artStyles = await fetchArtStyles({
        userAttributes: {
          host: req.headers.host,
        }
      });
    }

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~EXPERIMENTAL~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    This is an experimental method to dynamically set cache-control via CMS
    response.
    I'm not entirely sure how I feel about doing it this way.
    On the plus side, it allows rapid, no-code changes to cacheing on a
    per-page basis.
    On the downside, it abstracts cacheing logic across two (at minimum)
    layers - CMS & code

    Thoughts?
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    if (cacheControl && Object.keys(cacheControl).length > 0) {

      // Since we don't know the order the key values will come,
      // we'll make two headers to push based on the values that
      // we find in cacheControl.
      const cacheControlHeader = [];
      const noCacheHeader = [];

      // Iterate through the Cache Control obj and construct an array of
      // header values.
      // Cache-Control format relies on specific dash locations, so we grab this
      // from lookup table.
      // We then create a comma-separated string from the key-value pairs.
      Object.entries(cacheControl).forEach(([key, value]) => {
        if (typeof value === 'number' && key in ALLOWED_CACHE_HEADERS) {
          cacheControlHeader.push(
            `${ALLOWED_CACHE_HEADERS[key]}=${value > 3600 ? 3600 : value}`
          );
        }
        if (key === ALLOWED_CACHE_HEADERS.noCache && value === true) {
          // If we're doing no cache, we know what other values to add
          // No need to include them in the Builder Cache Control options.
          noCacheHeader.push(
            `${ALLOWED_CACHE_HEADERS[key]}=${value}`
          );
          noCacheHeader.push('private');
          noCacheHeader.push('max-age: 0');
        }
      });

      res.setHeader(
        'Cache-Control',
        noCacheHeader.length > 0 ? noCacheHeader : cacheControlHeader
      );
    }
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~END EXPERIMENT~~~~~~~~~~~~~~~~~~~~~~~ */
    return {
      props: {
        content,
        fetchReferralData,
        fetchUserData,
        includeFooter,
        includeNav,
        hideBuyButton,
        products,
        title,
        fetchMagicSvgData,
        artStyles,
      },
    };
  }

  BuilderPage.propTypes = {
    content: PropTypes.shape({
      data: PropTypes.shape({
        noindex: PropTypes.bool,
        url: PropTypes.string.isRequired,
      }),
    }).isRequired,
    products: PropTypes.shape({}),
    fetchReferralData: PropTypes.bool,
    fetchUserData: PropTypes.bool,
    includeFooter: PropTypes.bool,
    includeNav: PropTypes.bool,
    isBlogSearchFound: PropTypes.bool,
    isLatestArticles: PropTypes.bool,
    hideBuyButton: PropTypes.bool,
    title: PropTypes.string,
    additionalData: PropTypes.shape({}),
    fetchMagicSvgData: PropTypes.bool,
    artStyles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  };

  BuilderPage.defaultProps = {
    fetchReferralData: false,
    fetchUserData: false,
    includeFooter: true,
    includeNav: true,
    isBlogSearchFound: false,
    isLatestArticles: false,
    hideBuyButton: false,
    title: null,
    products: null,
    additionalData: {},
    fetchMagicSvgData: false,
    artStyles: null,
  };

  return {
    BuilderPage,
    getServerSideProps,
  };
}

export default builderPathInit;
