const builderTrackExperience = (content) => {
  if (!content?.testVariationId || !content?.testVariationName) return;

  const { id, name, testVariationId, testVariationName } = content;

  if (window.analytics) {
    window.analytics.track('Experiment Viewed', {
      experiment_id: id,
      experiment_name: name,
      variation_id: testVariationId,
      variation_name: testVariationName,
      testing_service: 'builder.io',
    });
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'experiment_viewed',
      experiment_id: id,
      experiment_name: name,
      variation_id: testVariationId,
      variation_name: testVariationName,
      testing_service: 'builder.io',
    });
  }
};

export default builderTrackExperience
