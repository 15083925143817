const defaultChild = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Glowforge:Card',
  },
  children: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Image',
        options: {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb38cbf61e22148aaad438fbf569e7829?width=500',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          lazy: false,
          fitContent: true,
        },
      },
      responsiveStyles: {
        large: {
          position: 'relative',
          minHeight: '20px',
          minWidth: '20px',
          overflow: 'hidden',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      children: [
        {
          component: {
            name: 'Glowforge:Text',
            options: {
              text: 'You can edit the contents of this example!',
            },
          },
        },
      ],
      responsiveStyles: {
        large: {
          position: 'relative',
          height: 'auto',
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
  ],
};

export default {
  name: 'Glowforge:Columns',
  noWrap: true,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fe1678514acda4922bdc5428c9d88f018?quality=60&width=200&height=200',
  defaultChildren: Array.from(Array(4)).map(() => defaultChild),
  defaultStyles: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '50px',
    paddingBottom: '50px',
    flexDirection: 'row',
  },
  inputs: [
    {
      name: 'gridGap',
      type: 'number',
      defaultValue: 20,
      helperText: 'Pixel size of gap between columns',
    },
    {
      name: 'desktopDefaultChildWidth',
      type: 'string',
      defaultValue: '25%',
      helperText:
      // eslint-disable-next-line max-len
        'Desktop default width of children. Can be overriden by inidividual children. Set to empty string to remove any default width applied.',
    },
    {
      name: 'mobileDefaultChildWidth',
      type: 'string',
      defaultValue: '100%',
      helperText:
      // eslint-disable-next-line max-len
        'Mobile default width of children. Can be overriden by inidividual children. Set to empty string to remove any default width applied.',
    },
    {
      name: 'justifyContent',
      type: 'string',
      defaultValue: 'center',
      enum: [
        'normal',
        'space-between',
        'space-around',
        'space-evenly',
        'center',
        'start',
        'end',
        'flex-start',
        'flex-end',
      ],
      advanced: true,
    },
    {
      name: 'alignItems',
      type: 'string',
      defaultValue: 'normal',
      enum: [
        'normal',
        'stretch',
        'center',
        'start',
        'end',
        'self-start',
        'self-end',
        'flex-start',
        'flex-end',
      ],
      advanced: true,
    },
    {
      name: 'stackColumnsAt',
      type: 'string',
      defaultValue: 'tablet',
      helperText: 'Convert horizontal columns to vertical at what device size',
      enum: ['tablet', 'mobile', 'never'],
      advanced: true,
    },
    {
      name: 'stackedGridGap',
      type: 'number',
      defaultValue: null,
      helperText: 'When stacking columns for mobile devices, set gap to be different for rows',
      advanced: true,
    },
    {
      name: 'reverseColumnsWhenStacked',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'When stacking columns for mobile devices, reverse the ordering',
      advanced: true,
    },
  ],
};
