import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const {
  publicRuntimeConfig: {
    GF_DOTCOM_GOOGLE_TAG_MANAGER_DATA_LAYER,
    GF_DOTCOM_SEGMENT_WRITE_KEY,
    GF_DOTCOM_OKENDO_APP_USER_ID,
    GF_DOTCOM_ENV,
  },
} = getConfig();

/**
 * The point of this file is that if we kept the scripts in the _document.jsx
 * file, we wouldn't be able to take full advantage of the next/script's various
 * strategies correctly (it ties into the _document.jsx file by laying scripts
 * into it dynamically, or so I believe)
 */

// Below rule in place since we don't control these 3p script loaders
/* eslint-disable react/no-danger, max-len */

const SegmentScript = () => (
  <Script
    id='segment-script'
    dangerouslySetInnerHTML={{
      __html: `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${GF_DOTCOM_SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("${GF_DOTCOM_SEGMENT_WRITE_KEY}");
        }}();
      `,
    }}
  />
);

const GTMScript = () => (
  <Script
    id='gtm-script'
    strategy='afterInteractive'
    dangerouslySetInnerHTML={{
      __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GF_DOTCOM_GOOGLE_TAG_MANAGER_DATA_LAYER}');
      `,
    }}
  />
);

const GAScript = () => (
  <Script
    id='gtag-init'
    strategy='afterInteractive'
    dangerouslySetInnerHTML={{
      __html: `
        window.dataLayer = window.dataLayer || [];
        window.gtag = window.gtag || function gtag(){dataLayer.push(arguments);};
        gtag('js', new Date());
      `,
    }}
  />
);

const OkendoScripts = () => {
  const router = useRouter();
  const { route } = router;
  if (route !== '/reviews') {
    return null;
  }

  const okendoWidgetScriptSrc =
    'https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js';

  return (
    <div>
      <Head>
        <meta name='oke:subscriber_id' content={GF_DOTCOM_OKENDO_APP_USER_ID} />
      </Head>
      <Script
        src={okendoWidgetScriptSrc}
        strategy='beforeInteractive'
        id='okendo'
      />
    </div>
  );
};

const ElevarScript = () => (
  <Script
    id='elevar'
    strategy='afterInteractive'
    dangerouslySetInnerHTML={{
      __html: `
      (async() => {
        try {
          const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/9135d93a2f28f2d08a09074f30aaac2120e55ed4/config.json");
          const config = await response.json();
          const scriptUrl = config.script_src_custom_pages;
          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config);
          }
        } catch (error) {
          console.error("Elevar Error:", error);
        }
      })()`,
    }}
  />
);

const BreadPayScript = () => {
  const isProduction = GF_DOTCOM_ENV === 'production';
  const breadScript = isProduction ? 'https://connect.breadpayments.com/sdk.js' : 'https://connect-preview.breadpayments.com/sdk.js';
  return (<Script id='breadpay' src={breadScript} />);
}
/* eslint-enable react/no-danger, max-len */

// Now let's put it all together
const Scripts = () => (
  <>
    <BreadPayScript />
    <ElevarScript />
    <GTMScript/>
    <GAScript />
    <SegmentScript />
    <OkendoScripts />
  </>
);

export default Scripts;
